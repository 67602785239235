import { defineStore } from 'pinia';

export const useLayoutStore = defineStore('layoutStore', {
    state: () => ({
        isReminderDialogOpen: false, // Состояние открытия диалога
    }),

    actions: {
        // Открытие диалога
        openReminderDialog() {
            this.isReminderDialogOpen = true;
        },
        // Закрытие диалога
        closeReminderDialog() {
            this.isReminderDialogOpen = false;
        },
        // Тоггл состояния диалога
        toggleReminderDialog() {
            this.isReminderDialogOpen = !this.isReminderDialogOpen;
        },
    },
});
