<template>
    <AppMainLayout title="Reminders">
        <!-- Таблица -->
        <template #content>
        <v-card>
            <v-card-title>
                <v-row style="align-items: center; padding: 10px; text-align: center; margin: 0px">
                    <v-icon style="margin-right: 10px;">mdi-calendar-clock</v-icon>
                    <span class="text-h5" v-if="backendPermissions.allowViewAllReminders">Agents Reminders</span>
                    <span class="text-h5" v-else>My Reminders</span>
                    <v-spacer></v-spacer>
                    <v-btn size="25" elevation="0" variant="plain" icon @click="openNewReminderDialog">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-row>
            </v-card-title>
            <v-card-text>
                <!-- Лоадер -->
                <v-container v-if="loading" class="text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-container>

                <!-- Таблица -->
                <v-data-table
                    v-if="!loading && reminders.length"
                    :headers="headers"
                    :items="reminders"
                    density="compact"
                    class="custom-table"
                    item-key="id"
                >
                    <template v-slot:headers="{ columns }">
                        <tr>
                            <template v-for="column in columns" :key="column.value">
                                <th v-if="column.value === 'email'" style="text-align: center; color: black">
                                    <v-icon>mdi-email</v-icon>
                                </th>
                                <th v-else-if="column.value === 'tg'" style="text-align: center; color: black">
                                    <v-icon>mdi-send</v-icon>
                                </th>
                                <th style="text-align: center; color: black" v-else>
                                    {{ column.title }}
                                </th>
                            </template>
                        </tr>
                    </template>
                    <template v-slot:body="{ items }">
                        <tr
                            v-for="(item, index) in items"
                            :key="item.id"
                            :class="{ hover: hoverRow === index, past: isPast(item.reminderOn) }"
                            @mouseover="hoverRow = index"
                            @mouseleave="hoverRow = null"
                        >
                            <td><a :href="'/requests/edit/'+item.requestId">{{ item.requestId }}</a></td>
                            <td><a :href="'/clients/edit/'+item.clientId">{{ item.clientName }}</a></td>
                            <td>{{ item.type }}</td>
                            <td>{{ item.addedOn }}</td>
                            <td>{{ item.addedBy }}</td>
                            <td> {{item.reminderOn}}
                                <v-badge v-if="item.repeat_every_x_days" color="green"  offset-x="4" offset-y="3" floating :content="item.repeat_every_x_days"><v-icon>mdi-calendar-sync-outline</v-icon> </v-badge>
                            </td>
                            <td>
                                <v-icon v-if="item.email">mdi-checkbox-marked</v-icon>
                                <v-icon v-if="!item.email">mdi-checkbox-blank-outline</v-icon>
                            </td>
                            <td>
                                <v-icon v-if="item.tg">mdi-checkbox-marked</v-icon>
                                <v-icon v-if="!item.tg">mdi-checkbox-blank-outline</v-icon>
                            </td>
                            <td>
                                <v-btn variant="plain" icon @click="deleteReminder(item)">
                                    <v-icon color="black">mdi-delete</v-icon>
                                </v-btn>
                                <v-btn variant="plain" icon @click="editReminder(item)">
                                    <v-icon color="black">mdi-pencil</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <!-- Если данные отсутствуют -->
                <v-container v-else class="text-center">
                    <p>No reminders available. Click the + button to add one.</p>
                </v-container>
            </v-card-text>
        </v-card>

        <!-- Диалог -->
        <v-dialog v-model="layoutStore.isReminderDialogOpen" max-width="1500px">
            <v-card>
                <v-card-title style="background-color: #f6f7f9; border: 1px solid #ddd; height: 70px">
                    <v-row style="align-items: center; padding: 10px; text-align: center; margin: 0px">
                        <v-icon style="margin-right: 10px;">mdi-calendar-clock</v-icon>
                        <span class="text-h5">{{ isEditMode ? 'Edit Reminder' : 'Reminder Setup' }}</span>
                        <v-spacer></v-spacer>
                        <v-btn size="25" elevation="0" variant="plain" icon @click="closeDialog">
                            <v-icon size="25">mdi-close</v-icon>
                        </v-btn>
                    </v-row>
                </v-card-title>

                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <!-- Тип напоминания -->
                            <v-col cols="4">
                                <v-select
                                    variant="outlined"
                                    v-model="reminderType"
                                    :items="reminderTypes"
                                    label="Reminder Type"
                                ></v-select>
                            </v-col>
                            <v-col cols="8" style="display: flex; justify-content: center">
                                <v-checkbox
                                    v-model="notifyEmail"
                                    label="Notify via Email"
                                    style="margin-right: 15px"
                                ></v-checkbox>
                                <v-checkbox v-model="notifyTelegram" label="Notify via Telegram"></v-checkbox>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    variant="outlined"
                                    v-model="freeText"
                                    label="Enter free text..."
                                    rows="3"
                                ></v-textarea>
                            </v-col>

                            <!-- Кнопки добавления -->
                            <v-col cols="12">
                                <v-btn v-if="!isEditMode" variant="text" @click="addDateTime">Add specific date & time</v-btn>
                            </v-col>
                        </v-row>

                        <!-- Даты и время -->
                        <v-row
                            v-for="(item, index) in dateTimeItems"
                            :key="index"
                            class="align-center"
                        >
                            <v-menu
                                v-model="item.dateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                content-class="menu-in-scroll"
                            >
                                <template #activator="{ props }">
                                    <v-text-field
                                        variant="outlined"
                                        v-model="item.date"
                                        label="Date"
                                        readonly
                                        v-bind="props"
                                        style="max-width: 170px; margin-right: 6px"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="item.date"
                                    :first-day-of-week="1"
                                    :min="new Date().toISOString().split('T')[0]"
                                    @click:save="item.dateMenu = false"
                                    @click:cancel="item.dateMenu = false"
                                ></v-date-picker>
                            </v-menu>

                            <time-picker-v v-model="item.time"></time-picker-v>
                            <v-select
                                variant="outlined"
                                v-model="item.timezone"
                                :items="timezones"
                                label="Timezone"
                                class="ml-2"
                                style="max-width: 150px; margin-right: 10px;"
                            ></v-select>
                            <v-text-field
                                v-model="item.repeat_every_x_days"
                                type="number"
                                variant="outlined"
                                min="1"
                                label="Repeat every (days)"
                                class="mr-2"
                                style="max-width: 170px;"
                            ></v-text-field>
                            <v-btn v-if="!isEditMode" density="compact" style="margin-bottom: 19px" variant="text" icon @click="removeDateTime(index)">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-row>

                    </v-container>
                </v-card-text>

                <v-card-actions style="height: 70px;">
                    <v-btn color="blue darken-1" variant="text" @click="closeDialog">Cancel</v-btn>
                    <v-btn variant="flat" color="primary" @click="saveReminder">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Snackbar -->
        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
            timeout="3000"
            bottom
            right
        >
            {{ snackbarMessage }}
        </v-snackbar>
        </template>
    </AppMainLayout>
</template>
<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { usePage } from '@inertiajs/vue3';
import { useLayoutStore } from '@store/pages/layout-store';
import TimePickerV from "@ui/TimePickerV.vue";
import AppMainLayout from "@layouts/AppMainLayout.vue";
import {useBackendMiddlewarePropsService} from "@services";

// Состояния
const layoutStore = useLayoutStore();
const page = usePage();

const reminders = ref([]);
const headers = ref([
    { title: 'Request Id', value: 'requestId' },
    { title: 'Client Name', value: 'clientName' },
    { title: 'Type', value: 'type' },
    { title: 'Added On', value: 'addedOn' },
    { title: 'Added By', value: 'addedBy' },
    { title: 'Reminder On', value: 'reminderOn' },
    { title: '<v-icon>mdi-email</v-icon>', value: 'email' },
    { title: 'Telegram', value: 'tg' },
    { title: 'Actions', value: 'actions', sortable: false },
]);

const loading = ref(false);
const snackbar = ref(false);
const snackbarMessage = ref('');
const snackbarColor = ref('success');
const hoverRow = ref(null);
const timezones = ['RMO', 'NYC'];
// Форма
const isEditMode = ref(false);
const reminderType = ref('');
const reminderTypes = [
    'Follow-Up Reminder',
    'PNR Deletion Reminder',
    'Check-in Reminder',
    'Pre-Trip Follow-Up',
    'Post-Trip Follow-Up',
    "Client's Birthday Reminder",
];
const freeText = ref('');
const notifyEmail = ref(false);
const notifyTelegram = ref(false);
const dateTimeItems = ref([{
    date: null,
    time: null,
    timezone: 'RMO',
    dateMenu: false,
    timeMenu: false,
    repeat_every_x_days: null
}]);
const repeatSetting = ref(null);
const requestId = ref(null);
const reminderId = ref(null);

// API запросы
async function fetchReminders() {
    loading.value = true;
    try {
        const allowViewAll = backendPermissions.allowViewAllReminders
        const response = await axios.get('/api/reminders', {
            params: { user_id: allowViewAll ? -1 : page.props.auth_user.id },
        });
        reminders.value = response.data.data.map(reminder => ({
            id: reminder.id,
            clientName: reminder.clientName,
            clientId: reminder.clientId,
            requestId: reminder.requestId,
            type: reminder.type,
            addedOn: new Date(reminder.addedOn).toLocaleDateString(),
            reminderOn: `${reminder.reminderOn} ${reminder.reminderTime || ''}`,
            email: reminder.email,
            tg: reminder.tg,
            addedBy: reminder.addedBy,
            repeat_every_x_days: reminder.repeat_every_x_days,
        })) .sort((a, b) => new Date(a.addedOn) - new Date(b.addedOn));
    } catch (error) {
        snackbarMessage.value = 'Error fetching reminders';
        snackbarColor.value = 'error';
        snackbar.value = true;
    } finally {
        loading.value = false;
    }
}

function openNewReminderDialog() {
    isEditMode.value = false;
    resetForm();
    layoutStore.openReminderDialog();
}

function closeDialog() {
    layoutStore.closeReminderDialog();
    resetForm();
}

function resetForm() {
    reminderType.value = '';
    freeText.value = '';
    notifyEmail.value = false;
    notifyTelegram.value = false;
    dateTimeItems.value = [{
        date: null,
        time: null,
        timezone: 'RMO',
        dateMenu: false,
        timeMenu: false,
        repeat_every_x_days: null
    }];
}
const {backendPermissions} = useBackendMiddlewarePropsService();
async function saveReminder() {
    const getTimeZone = (timezone) => {
        const timeZoneMap = {
            RMO: 'Europe/Chisinau',
        };
        return timeZoneMap[timezone] || 'America/New_York';
    };

    const payload = {
        type: reminderType.value,
        text: freeText.value,
        tg: notifyTelegram.value,
        email: notifyEmail.value,
        request_id: requestId.value,
        created_by_agent_id: page.props.auth_user.id ?? null,
        created_for_agent_id: page.props.auth_user.id ?? null,
        special_repeats: dateTimeItems.value.map(item => {
            const timezone = getTimeZone(item.timezone);
            const localDate = new Date(item.date);
            const options = { timeZone: timezone, year: 'numeric', month: '2-digit', day: '2-digit' };
            const formattedDate = new Intl.DateTimeFormat('en-CA', options).format(localDate);
            return {
                date: formattedDate,
                time: item.time,
                timezone: item.timezone,
                repeat_every_x_days: item.repeat_every_x_days
            };
        }),
    };
    if (!freeText.value) {
        snackbarMessage.value = "You must fill in the reminder text.";
        snackbarColor.value = "error";
        snackbar.value = true;
        return;
    }
    if (!payload.repeat_every_x_days && payload.special_repeats.length === 0) {
        snackbarMessage.value = "Specify a repeat schedule or special dates.";
        snackbarColor.value = "error";
        snackbar.value = true;
        return;
    }
    try {
        if (isEditMode.value) {
            await axios.put(`/api/reminders/${reminderId.value}`, payload);
            snackbarMessage.value = 'Reminder updated successfully!';
        } else {
            await axios.post('/api/reminders', payload);
            snackbarMessage.value = 'Reminder created successfully!';
        }
        snackbarColor.value = 'success';
        snackbar.value = true;
        await fetchReminders();
        closeDialog();
    } catch (error) {
        console.log('error', error);
        snackbarMessage.value = 'Error saving reminder';
        snackbarColor.value = 'error';
        snackbar.value = true;
    }
}

async function editReminder(reminder) {
    console.log('Editing reminder:', reminder); // Для отладки
    isEditMode.value = true; // Устанавливаем режим редактирования
    layoutStore.openReminderDialog(); // Открываем диалог
    requestId.value = reminder.requestId
    reminderId.value = reminder.id
    try {
        loading.value = true;

        // Получаем данные напоминания через API
        const response = await axios.get(`/api/reminders/${reminder.id}`);
        const data = response.data.data;

        // Заполняем форму данными
        reminderType.value = data.type || '';
        freeText.value = data.text || '';
        notifyEmail.value = data.email || false;
        notifyTelegram.value = data.tg || false;

        // Заполняем специальные даты и время
        dateTimeItems.value =[{
            date: data.date ? new Date(data.date) : null,
            time: data.time || null,
            timezone: data.timezone || 'RMO',
            repeat_every_x_days: data.repeat_every_x_days,
            dateMenu: false,
        }];
    } catch (error) {
        console.error('Error loading reminder for edit:', error);
        snackbarMessage.value = 'Failed to load reminder for editing.';
        snackbarColor.value = 'error';
        snackbar.value = true;
    } finally {
        loading.value = false;
    }
}

async function deleteReminder(reminder) {
    const confirmDeletion = confirm(`Are you sure you want to delete the reminder "${reminder.type}"?`);
    if (!confirmDeletion) return;

    try {
        await axios.delete(`/api/reminders/${reminder.id}`);
        reminders.value = reminders.value.filter(item => item.id !== reminder.id);
        snackbarMessage.value = 'Reminder deleted successfully';
        snackbarColor.value = 'success';
        snackbar.value = true;
    } catch (error) {
        snackbarMessage.value = 'Error deleting reminder';
        snackbarColor.value = 'error';
        snackbar.value = true;
    }
}

function addDateTime() {
    dateTimeItems.value.push({
        date: null,
        time: null,
        dateMenu: false,
    });
}

function removeDateTime(index) {
    dateTimeItems.value.splice(index, 1);
}

function addRepeatSetting() {
    if (!repeatSetting.value) {
        repeatSetting.value = { interval: 1, time: null };
    }
}

function removeRepeatSetting() {
    repeatSetting.value = null;
}

function isPast(date) {
    return new Date(date) < new Date();
}

// При загрузке
fetchReminders();
</script>

<style>
.custom-table {
    border: 1px solid #ddd;
    border-collapse: collapse;
}

.custom-table thead {
    background-color: #f6f7f9;
    color: #495057;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
}

.custom-table tbody tr {
    transition: background-color 0.2s;
}

.custom-table tbody tr.hover {
    background-color: #f1f3f5;
}

.custom-table tbody tr.past {
    background-color: #f8d7da;
}

.custom-table th,
.custom-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
}

.text-h5 {
    font-size: 20px;
    font-weight: bold;
}

.v-btn {
    margin-right: 10px;
}

</style>

