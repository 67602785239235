<script setup>

import {Head} from "@inertiajs/vue3";
import AppMainLayout from "@layouts/AppMainLayout.vue";
const props = defineProps({
    url: String,
    error: String,
});
</script>

<template>
    <Head title="GDS - VIP Fares"></Head>
    <AppMainLayout title="GDS">
        <template #content>
            <div v-if="props.url && !props.error">
<!--                123-->
                <iframe :src="url" style="width: 100%; height: 100vh; border: none;"></iframe>
            </div>
            <div v-else>
                <span>
                    {{props.message}}
                </span>
            </div>

        </template>
    </AppMainLayout>
</template>

<style scoped lang="scss">

</style>
