<template>
    <acms-v-tabs
        :items="getTabsItems"
        class="requests-tabs-block"
        content-class="p-0"
        navWrapClass="bg-white"
        @beforeChangeTab="$emit('changeActiveRequestTab', $event)  "
        parentControl
        :active="currentActiveRequestTab"
    >
        <template #nav-right>
<!--            <div style="display: flex; flex-direction: row">-->
<!--                <a v-if="backPerms.backendPermissions.allowButtonKayak" class="btn-grad-kayak acms-v-btn btn btn-sm me-2"  :href="currentKayakString"  target="_blank" rel="noopener noreferrer">Kayak <img  width="20" height="20" src="/media/external/link.svg" style="filter: invert(100%)" alt="link-icon"></a>-->
<!--                <a v-if="backPerms.backendPermissions.allowButtonSkyscanner" class="btn-grad-skyscanner acms-v-btn btn btn-sm me-2" :href="currentSkyScannerString" target="_blank" rel="noopener noreferrer">Skyscanner   <img  width="20" height="20" style="filter: invert(100%)" src="/media/external/link.svg" alt="link-icon"></a>-->
<!--                <a  v-if="backPerms.backendPermissions.allowButtonGflight" class="btn-grad-google acms-v-btn btn btn-sm me-2" :href="currentGoogleFlightsString" target="_blank" rel="noopener noreferrer">G Flights <img width="20" height="20" style="filter: invert(100%)" src="/media/external/link.svg" alt="link-icon"></a>-->
<!--                <a v-if="backPerms.backendPermissions.allowButtonPicasso" class="btn-grad-picasso acms-v-btn btn btn-sm me-2" @click="setPropertiesPicasso" rel="noopener noreferrer">Picasso<img width="20" height="20" style="filter: invert(100%)" src="/media/external/link.svg" alt="link-icon"></a>-->
<!--                <a v-if="backPerms.backendPermissions.allowButtonMatrix" class="btn-grad-matrix acms-v-btn btn btn-sm me-2" :href="currentMatrixString" target="_blank" rel="noopener noreferrer">Matrix <img width="20" height="20" style="filter: invert(100%)" src="/media/external/link.svg" alt="link-icon"></a>-->
<!--                <a v-if="backPerms.backendPermissions.allowButtonBasis" class="btn-grad-basis acms-v-btn btn btn-sm me-2" :href="currentBasisString" target="_blank" rel="noopener noreferrer">Basis <img width="20" height="20" style="filter: invert(100%)" src="/media/external/link.svg" alt="link-icon"></a>-->
<!--            </div>-->

            <div style="display:flex; align-items: center">
                <!-- Кнопки для больших экранов -->
                <div class="d-lg1-flex" style="margin-left: 10px">
                    <a v-if="backPerms.backendPermissions.allowButtonKayak" class="btn-grad-kayak acms-v-btn btn btn-sm me-2"  :href="currentKayakString"  target="_blank" rel="noopener noreferrer">Kayak <img  width="20" height="20" src="/media/external/link.svg" style="filter: invert(100%)" alt="link-icon"></a>
                    <a v-if="backPerms.backendPermissions.allowButtonSkyscanner" class="btn-grad-skyscanner acms-v-btn btn btn-sm me-2" :href="currentSkyScannerString" target="_blank" rel="noopener noreferrer">Skyscanner   <img  width="20" height="20" style="filter: invert(100%)" src="/media/external/link.svg" alt="link-icon"></a>
                    <a  v-if="backPerms.backendPermissions.allowButtonGflight" class="btn-grad-google acms-v-btn btn btn-sm me-2" :href="currentGoogleFlightsString" target="_blank" rel="noopener noreferrer">G Flights <img width="20" height="20" style="filter: invert(100%)" src="/media/external/link.svg" alt="link-icon"></a>
                    <a v-if="backPerms.backendPermissions.allowButtonPicasso" class="btn-grad-picasso acms-v-btn btn btn-sm me-2" @click="setPropertiesPicasso" rel="noopener noreferrer">Picasso<img width="20" height="20" style="filter: invert(100%)" src="/media/external/link.svg" alt="link-icon"></a>
                    <a v-if="backPerms.backendPermissions.allowButtonMatrix" class="btn-grad-matrix acms-v-btn btn btn-sm me-2" :href="currentMatrixString" target="_blank" rel="noopener noreferrer">Matrix <img width="20" height="20" style="filter: invert(100%)" src="/media/external/link.svg" alt="link-icon"></a>
                    <a v-if="backPerms.backendPermissions.allowButtonBasis" class="btn-grad-basis acms-v-btn btn btn-sm me-2" :href="currentBasisString" target="_blank" rel="noopener noreferrer">Basis <img width="20" height="20" style="filter: invert(100%)" src="/media/external/link.svg" alt="link-icon"></a>
                </div>

                <!-- Выпадающее меню для маленьких экранов -->
                <div class="d-lg1-none">
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn style="margin-left: 20px" density="comfortable" color="secondary" variant="outlined" v-bind="props" >
                                Show Links
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-if="backPerms.backendPermissions.allowButtonKayak" :href="currentKayakString" target="_blank" rel="noopener noreferrer">
                                <v-list-item-title>Kayak</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="backPerms.backendPermissions.allowButtonSkyscanner" :href="currentSkyScannerString" target="_blank" rel="noopener noreferrer">
                                <v-list-item-title>Skyscanner</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="backPerms.backendPermissions.allowButtonGflight" :href="currentGoogleFlightsString" target="_blank" rel="noopener noreferrer">
                                <v-list-item-title>G Flights</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="backPerms.backendPermissions.allowButtonPicasso" @click="setPropertiesPicasso">
                                <v-list-item-title>Picasso</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="backPerms.backendPermissions.allowButtonMatrix" :href="currentMatrixString" target="_blank" rel="noopener noreferrer">
                                <v-list-item-title>Matrix</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="backPerms.backendPermissions.allowButtonBasis" :href="currentBasisString" target="_blank" rel="noopener noreferrer">
                                <v-list-item-title>Basis</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
            <slot name="nav-right"></slot>
        </template>
        <template #nav-btn="{item}">
            <div class="d-flex  align-items-start">
                <div class="d-flex align-items-start  flex-column me-2">
                    <div class="tabs-tab-title d-flex">
                        <span>{{ item?.type }}:</span>

                        <div style="margin-left: 2px;" class="d-flex">
                            <template v-if="item?.isMcType">
                                    <span v-tooltip="item.mcTypeContent.tooltip">
                                        {{ item.mcTypeContent.firstAndLastLeg }}
                                    </span>
                            </template>
                            <template v-else>
                                <template v-for="item in item.legs">
                                    <span v-if="item == '*'" style="width: 5px"> </span>
                                    <span v-else>{{ item }}</span>
                                </template>
                            </template>
                        </div>
                    </div>
                    <div class="text-uppercase  f-12 text-start">
                        <template v-for="(itemDate,index) in item.dates">
                            <br v-if="itemDate == '*' && item.dates?.length == 3">
                            <template v-else>
                                <br v-if="itemDate == '*'">
                                <span class="text-gray fw-medium" v-else >{{ itemDate }}</span>
                            </template>

                        </template>
                    </div>
                </div>
                <div class="circle-quantity" v-if="item?.quantity">{{ item.quantity }}</div>
            </div>

        </template>
        <template>
        </template>
    </acms-v-tabs>
</template>
<script>
export default {
    name: "PartialTabsCurrentSelectedRequest",
}
</script>

<script setup="">
import {computed, onMounted} from "vue";
import {useBackendMiddlewarePropsService} from "@services";
const props = defineProps({
    requests: Array,
    currentActiveRequestTab: [String, Number],
    pqs: Object,
})
const backPerms = useBackendMiddlewarePropsService();
const emit = defineEmits(['changeActiveRequestTab', ''])

const getTabsItems = computed(() => {

    const getFirstAndLastLeg = (bool, legs = []) => {
        if (bool) {
            const legsSeparated = legs.join('')
            const legsArray = legsSeparated.split('*')
            const firstItem = legsArray.at(0)
            const lastItem = legsArray.at(-1)

            const firstFrom = firstItem.split(('-'))?.at(0) ?? ''
            const lastTo = lastItem.split(('-'))?.at(-1) ?? ''

            return {
                tooltip: legsSeparated.replaceAll('*', ' '),
                firstAndLastLeg: [firstFrom, lastTo].join('-'),
            }
        }
        return false
    }
    return props.requests.reduce((acc, item) => {
        const {dates = null, legs = null, type = null} = item?.pq_tab_details
        acc[item.id] = {
            id: item.id,
            type,
            legs,
            dates,
            quantity: props.pqs?.[item.id]?.length ?? 0,
            isMcType: type.toLowerCase() === 'mc', //
            mcTypeContent: getFirstAndLastLeg(type.toLowerCase() === 'mc', legs)
        }
        return acc
    }, {})

})
const encodeBase64 = (str) => {
    return btoa(unescape(encodeURIComponent(str)));
};
const cabin = (cabinClass) => {
    switch (cabinClass) {
        case 1:
            return 'business';
        case 2:
            return 'economy';
        case 3:
            return 'first';
        case 4:
            return 'premium';
        default:
            return 'business';
    }
}
const cabinSkysacnner = (cabinClass) => {
    switch (cabinClass) {
        case 1:
            return 'business';
        case 2:
            return 'economy';
        case 3:
            return 'first';
        case 4:
            return 'premium_economy';
        default:
            return 'business';
    }
}

const cabin4picasso = (cabinClass) => {
    switch (cabinClass) {
        case 1:
            return 'business';
        case 2:
            return 'economy';
        case 3:
            return 'first';
        case 4:
            return 'premium Economy';
        default:
            return 'business';
    }
}

const firstTabKey = computed(() => {
    return props.requests[0]?.id
})

const currentKayakString  = computed(() => {
    let request;
    if (props.currentActiveRequestTab){
        request = props.requests.find((request) => request.id === props.currentActiveRequestTab);
    } else {
        request = props.requests[0];

    }
    const cabinClass = cabin(request.cabin_class_id);
    switch (request.pq_tab_details?.type) {
        case 'RT' : return  `https://www.kayak.com/flights/${request.pq_tab_details.legs[0]}-${request.pq_tab_details.legs[2]}/${request.flight_legs[0].flight_date_from}/${request.flight_legs[1].flight_date_from}/${cabinClass}?sort=price_a`;
        case 'OW' : return `https://www.kayak.com/flights/${request.pq_tab_details.legs[0]}-${request.pq_tab_details.legs[2]}/${request.flight_legs[0].flight_date_from}/${cabinClass}?sort=bestflight_a`;
        default : return  'https://www.kayak.com'
    }
})



const currentSkyScannerString  = computed(() => {
    let request;
    if (props.currentActiveRequestTab){
        request = props.requests.find((request) => request.id === props.currentActiveRequestTab);
    } else {
        request = props.requests[0];

    }
    const cabinClass = cabinSkysacnner(request.cabin_class_id);
    switch (request.pq_tab_details?.type) {
         case 'RT' : return  `https://www.skyscanner.com/transport/flights/${request.pq_tab_details.legs[0]}/${request.pq_tab_details.legs[2]}/${request.flight_legs[0].flight_date_from.replace(/-/g, '')}/${request.flight_legs[1].flight_date_from.replace(/-/g, '')}/?adultsv2=1&cabinclass=${cabinClass}&childrenv2=&currency=USD&inboundaltsenabled=false&locale=en-GB&market=US&outboundaltsenabled=false&preferdirects=false&ref=home&rtn=1`;
        case 'OW' : return  `https://www.skyscanner.com/transport/flights/${request.pq_tab_details.legs[0]}/${request.pq_tab_details.legs[2]}/${request.flight_legs[0].flight_date_from.replace(/-/g, '')}/?adultsv2=1&cabinclass=${cabinClass}&childrenv2=&currency=USD&inboundaltsenabled=false&locale=en-GB&market=US&outboundaltsenabled=false&preferdirects=false&ref=home&rtn=1`;
        default : return  'https://www.skyscanner.com'
    }
})

const setPropertiesPicasso = async () => {
    let request;
    if (props.currentActiveRequestTab){
        request = props.requests.find((request) => request.id === props.currentActiveRequestTab);
    } else {
        request = props.requests[0];

    }
    if (request.pq_tab_details?.type === 'OW' || 'RT') {

        function capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }

        const cabinClass = capitalizeFirstLetter(cabin4picasso(request.cabin_class_id));
        let dep = '';
        let arr = '';
        let depDate = '';
        let arrDate = '';
        if (request.pq_tab_details?.type) {
            dep = request.pq_tab_details.legs[0];
            arr = request.pq_tab_details.legs[2];
            depDate = request.flight_legs[0].flight_date_from;
            arrDate = request.pq_tab_details?.type === 'RT' ? request.flight_legs[1].flight_date_from : '';
        }
        const data = {
            departure: dep,
            arrival: arr,
            departureDate: depDate,
            returnDate: arrDate,
            cabin: cabinClass,
            search: "yes",
            tripType: request.pq_tab_details?.type
        };

        window.postMessage({
            type: 'FROM_PAGE',
            action: 'startAutomation',
            data: data
        }, '*');
    }
    else {
        alert ('Available only for Round Trip & One Way')
    }

}

const currentGoogleFlightsString = computed(() => {

    let request;
    if (props.currentActiveRequestTab) {
        request = props.requests.find((request) => request.id === props.currentActiveRequestTab);
    } else {
        request = props.requests[0];
    }

    const cabinClassCodeOW = (cabinClass) => {
        let res = '';
        switch (cabinClass) {
            case 'business':
                res = 'A3ABggELCP';
                break;
            case 'first':
                res = 'BHABggELCP';
                break;
            case 'premium':
                res = 'AnABggELCP';
                break;
            default:
                res = 'AXABggELCP';  // Эконом по умолчанию
        }
        return res
    }

    const cabinClassCodeRT = (cabinClass) => {
        let res = '';
        switch (cabinClass) {
            case 'business':
                res = 'DcAGCAQsI';
                break;
            case 'first':
                res = "EcAGCAQsI";
                break;
            case 'premium':
                res = "CcAGCAQsI";
                break;
            default:
                res = "BcAGCAQsI";  // Эконом по умолчанию
        }
        return res
    }

    const cabinClassCodeMC = (cabinClass) => {
        let res = '';
        switch (cabinClass) {
            case 'business':
                res = "SANwAYIBCwj";
                break;
            case 'first':
                res = "SARwAYIBCwj";
                break;
            case 'premium':
                res = "SAJwAYIBCwj";
                break;
            default:
                res = "SAFwAYIBCwj";  // Эконом по умолчанию
        }
        return res
    }

    const cabinClassCodeMC4 = (cabinClass) => {
        let res = '';
        switch (cabinClass) {
            case 'business':
                res = "QAFIA3A";
                break;
            case 'first':
                res = "QAFIBHA";
                break;
            case 'premium':
                res = "QAFIAnA";
                break;
            default:
                res = "QAFIAXA";  // Эконом по умолчанию
        }
        return res
    }


    const encodeBase64 = (str) => {// Преобразуем байты в base64
        return btoa(str);
    };
    const encodeDateWithSpecialChar = (date) => {
        const specialChar = String.fromCharCode(18);  // Символ DC2 (Device Control 2)
        const fullString = `${specialChar}\n${date}`;  // Добавляем DC2 перед датой
        return encodeBase64(fullString);  // Кодируем в base64
    };
    // ---------------------------------------------------------------------------------
    // 1)
    let baseURL = 'https://www.google.com/travel/flights/search?tfs=CBwQAhoe';

   //2)
    const departureDate = encodeDateWithSpecialChar(request.flight_legs[0].flight_date_from);
    baseURL+=departureDate;
    //3)
    baseURL+='agcIARID';

    //4)
    const departureIATA = encodeBase64(request.flight_legs[0].departure_airport_id);  // Код вылета в base64
    baseURL+=departureIATA;

    //5)
    baseURL+='cgcIARID';
    //6)
    const arrivalIATA = encodeBase64(request.flight_legs[0].arrival_airport_id);  // Код прибытия в base64
    baseURL+=arrivalIATA;

    if (request.pq_tab_details?.type === 'OW') {
        //7
        baseURL+='QAFI';
        //8
        const cabinClass = cabinClassCodeOW(cabin(request.cabin_class_id));
        baseURL+=cabinClass;
        //9
        baseURL+='___________wGYAQI';
        //10
        baseURL+='&tfu=EgYIAhAAGAA';
        //11
        baseURL+='&curr=USD';
    }
    //RT
    else if (request.pq_tab_details?.type === 'RT' || request.pq_tab_details?.type === 'DOJ' || request.pq_tab_details?.type === 'OJ' ) {
        //7
        const returnDate  = request.flight_legs[request.flight_legs.length-1].flight_date_to ?? request.flight_legs[request.flight_legs.length-1].flight_date_from
        const basedrDate = encodeBase64('\n'+returnDate+'j');
        baseURL+= basedrDate;
        //8
        baseURL+='BwgB';
        //9
        const depCity2 =encodeBase64("" + request.flight_legs[request.flight_legs.length-1].departure_airport_id + 'r');
        baseURL+=depCity2;
        //10
        const arrCity2 = encodeBase64('\b' +request.flight_legs[request.flight_legs.length-1].arrival_airport_id + '@');
        baseURL+=arrCity2;
        //11
        baseURL+='AUg';
        //12
        // const cabinClass = request.pq_tab_details?.type === 'RT' ? cabinClassCodeRT(cabin(request.cabin_class_id)) : cabinClassCodeMC(cabin(request.cabin_class_id));
        const cabinClass = cabinClassCodeRT(cabin(request.cabin_class_id));
        baseURL+=cabinClass;
        //13
        // baseURL+=request.pq_tab_details?.type === 'RT' ?  '____________AZgBAQ' :  '____________AZgBAw';
        baseURL+= request.pq_tab_details?.type === 'RT' ? '____________AZgBAQ' :  '____________AZgBAw';
        //14
        baseURL+='&tfu=EgYIAhAAGAA';
        //15
        baseURL+='&curr=USD';
    }
    else if (request.pq_tab_details?.type === 'MC') {
        //7
        const returnDate1  = request.flight_legs[1].flight_date_to ?? request.flight_legs[1].flight_date_from
        const basedrDate1 = encodeBase64('\n'+returnDate1+'j');
        baseURL+= basedrDate1;
        //8
        baseURL+='BwgB';
        //9
        const depCity2 =encodeBase64("" + request.flight_legs[1].departure_airport_id + 'r');
        baseURL+=depCity2;
        //10
        const arrCity2 = encodeBase64("" +request.flight_legs[1].arrival_airport_id + "\n");
        baseURL+=arrCity2;
        //11
        const returnDate2  = request.flight_legs[2].flight_date_to ?? request.flight_legs[2].flight_date_from
        const basedrDate2 = encodeBase64(returnDate2+"j");
        baseURL+= basedrDate2;
        baseURL+='CAES';
        //ORD
        const depCity3 =encodeBase64("" + request.flight_legs[2].departure_airport_id + 'r'+ "");
        baseURL+= depCity3;
        baseURL+='CAES';
        if (request.flight_legs.length === 3) {
            const arrCity3 = encodeBase64("" +request.flight_legs[2].arrival_airport_id + "@");
            baseURL+=arrCity3;

            const cabinClass = cabinClassCodeMC(cabin(request.cabin_class_id));
            baseURL+=cabinClass;
            //13
            baseURL+='___________8BmAED';
        }
        else {
            const arrCity3 = encodeBase64("" +request.flight_legs[2].arrival_airport_id + "");
            baseURL+=arrCity3;
            const depDate4 = encodeBase64("\n" + request.flight_legs[3].flight_date_from + "j");
            baseURL+= depDate4;
            const depCity4 = encodeBase64("" + request.flight_legs[3].departure_airport_id + "r")
            baseURL+=depCity4;
            const arrCity4 = encodeBase64("" + request.flight_legs[3].arrival_airport_id);
            baseURL+=arrCity4;
            const cabinClass= cabinClassCodeMC4(cabin(request.cabin_class_id));
            baseURL+=cabinClass;
            baseURL+='BggELCP___________wGYAQM';
        }
        //vie

        //14
        baseURL+='&tfu=EgYIAhAAGAA';
        //15
        baseURL+='&curr=USD';
    }
    return baseURL;
});
onMounted(() => {
    if (!props.currentActiveRequestTab) {
        emit('changeActiveRequestTab', firstTabKey.value)
    }
})

const currentMatrixString = computed(() => {
    let request;
    if (props.currentActiveRequestTab) {
        request = props.requests.find((request) => request.id === props.currentActiveRequestTab);
    } else {
        request = props.requests[0];
    }

    const cabinClassMap = {
        1: 'BUSINESS',
        2: 'COACH',
        3: 'FIRST',
        4: 'PREMIUM-COACH'
    };
    let type = 'one-way'
    switch (request.pq_tab_details?.type) {
        case 'RT': {type = 'round-trip'; break}
        case 'OJ': {type = 'multi-city'; break}
        case 'DOJ':{type = 'multi-city'; break}
        case 'MC':{type = 'multi-city'; break}
        default: type ='one-way';
    }
    const jsonData = {
        type: type,
        slices: [
            {
                origin: [request.flight_legs[0].departure_airport_id],
                dest: [request.flight_legs[0].arrival_airport_id],
                dates: {
                    searchDateType: 'specific',
                    departureDate: request.flight_legs[0].flight_date_from,
                    departureDateType: 'depart',
                    departureDateModifier: '0',
                    departureDatePreferredTimes: [],
                    returnDate: request.pq_tab_details?.type === 'RT' ? request.flight_legs[1].flight_date_from : '',
                    returnDateType: 'depart',
                    returnDateModifier: '0',
                    returnDatePreferredTimes: []
                }
            }
        ],
        options: {
            cabin: cabinClassMap[request.cabin_class_id] || 'COACH',
            stops: '-1',
            extraStops: '1',
            allowAirportChanges: 'true',
            showOnlyAvailable: 'true'
        },
        pax: {
            adults: '1'
        }
    };

    if (request.pq_tab_details?.type === 'MC' || request.pq_tab_details?.type === 'OJ' || request.pq_tab_details?.type === 'DOJ' ) {
        jsonData.type = 'multi-city';
        jsonData.slices = request.flight_legs.map((leg) => ({
            origin: [leg.departure_airport_id],
            dest: [leg.arrival_airport_id],
            dates: {
                searchDateType: 'specific',
                departureDate: leg.flight_date_from,
                departureDateType: 'depart',
                departureDateModifier: '0',
                departureDatePreferredTimes: []
            }
        }));
    }

    const encodedJson = btoa(JSON.stringify(jsonData));
    return `https://matrix.itasoftware.com/search?search=${encodedJson}`;
});
const cabinMap = {
    1: 'Business',
    2: 'Economy',
    3: 'First',
    4: 'Premium Economy',
};
const currentBasisString = computed(() => {
    let request;
    if (props.currentActiveRequestTab) {
        request = props.requests.find((request) => request.id === props.currentActiveRequestTab);
    } else {
        request = props.requests[0];
    }

    // Определение типа полета
    let tripType;
    switch (request.pq_tab_details?.type) {
        case 'RT':
            tripType = 'roundtrip';
            break;
        case 'OW':
            tripType = 'oneway';
            break;
        case 'OJ':
        case 'DOJ':
        case 'MC':
            tripType = 'oneway';
            break;
        default:
            tripType = 'oneway';
            break;
    }

    // Определение класса кабины
    const cabinClass = cabinMap[request.cabin_class_id] || 'Economy';

    // Формирование структуры JSON для OW и RT
    const stops = tripType === 'roundtrip'
        ? [
            {
                origin: [{ code: request.flight_legs[0].departure_airport_id, city: request.flight_legs[0].departure_airport_object.title }],
                destination: [{ code: request.flight_legs[0].arrival_airport_id, city: request.flight_legs[0].arrival_airport_object.title }],
                date: { value: request.flight_legs[0].flight_date_from, range: 0 },
            },
            {
                origin: [{ code: request.flight_legs[1].departure_airport_id, city: request.flight_legs[1].departure_airport_object.title  }],
                destination: [{ code: request.flight_legs[1].arrival_airport_id, city: request.flight_legs[1].arrival_airport_object.title }],
                date: { value: request.flight_legs[1].flight_date_from, range: 0 },
            },
        ]
        : [
            {
                origin: [{ code: request.flight_legs[0].departure_airport_id, city: request.flight_legs[0].departure_airport_object.title }],
                destination: [{ code: request.flight_legs[0].arrival_airport_id, city: request.flight_legs[0].arrival_airport_object.title }],
                date: { value: request.flight_legs[0].flight_date_from, range: 0 },
            },
        ];
    // console.log('stops', stops)
    // Формирование JSON данных для запроса
    const jsonData = {
        tripType,
        stops,
        pax: '1',
        cabin: cabinClass,
        programs: ['AM', 'AC', 'KL', 'AS', 'AA', 'AV', 'BA', 'CM', 'DL', 'EK', 'EY', 'IB', 'B6', 'QF', 'SK', 'SQ', 'NK', 'TP', 'TK', 'UA', 'VS', 'VA'],
    };

    // Кодирование JSON-строки в Base64
    const encodedJson = encodeBase64(JSON.stringify(jsonData));
    return `https://agentsearch.vercel.app/search?s=${encodedJson}`;
});


</script>

<style lang="scss">
.btn-grad-kayak {
    background-image: linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%);
    margin: 1px!important;
    padding: 15px 45px;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3.2px;
    display: block;
    height: 30px;
    align-self: center;
}

.btn-grad-kayak:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}


.btn-grad-skyscanner {
    background-image: linear-gradient(to right, #457fca 0%, #5691c8  51%, #457fca  100%);
    margin: 1px!important;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3.2px;
    display: block;
    height: 30px;

    align-self: center;
}

.btn-grad-skyscanner:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}


.btn-grad-google {
    background-image: linear-gradient(to right, #4568DC 0%, #B06AB3 51%, #4568DC 100%);
    margin: 1px!important;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3.2px;
    display: block;
    height: 30px;

    align-self: center;
}

.btn-grad-google:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
}

.btn-grad-google {
    background-image: linear-gradient(to right, #4568DC 0%, #B06AB3 51%, #4568DC 100%);
    margin: 1px!important;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3.2px;
    display: block;
    height: 30px;

    align-self: center;
}


.btn-grad-picasso {
    background-image: linear-gradient(to right, #e52d27 0%, #b31217  51%, #e52d27  100%);
    margin: 1px!important;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3.2px;
    height: 30px;
    display: block;
    align-self: center;
}

.btn-grad-picasso:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.btn-grad-matrix {
    background-image: linear-gradient(to right, #4B79A1 0%, #283E51 51%, #4B79A1 100%);
    margin: 1px!important;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3.2px;
    height: 30px;
    display: block;
    align-self: center;
}

.btn-grad-matrix:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
}

.btn-grad-basis {
    background-image: linear-gradient(to right, #606c88 0%, #3f4c6b 51%, #606c88 100%);
    margin: 1px!important;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 3.2px;
    height: 30px;
    align-self: center;
    display: block;
}

.btn-grad-basis:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
}
.d-lg1-flex {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.d-lg1-none {
    display: none;
}

@media (max-width: 1635px) {
    .d-lg1-flex {
        display: none !important;
    }
    .d-lg1-none {
        display: block !important;
    }
}
</style>
