<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        @click:outside="saveTime"
    >
        <template v-slot:activator="{ props }">
            <v-text-field
                v-bind="props"
                v-model="formattedTime"
                label="Select Time"
                readonly
                outlined
                dense
                variant="outlined"
                style="margin-left: 1px"
                class="select-time-field"
            />
        </template>
        <v-card class="time-selection-card">
            <v-card-text>
                <div class="d-flex">
                    <v-select
                        v-model="selectedHour"
                        :items="hours"
                        label="Hour"
                        dense
                        outlined
                        :menu-props="{ value: true }"
                        class="dropdown-field"
                    />
                    <v-select
                        v-model="selectedMinute"
                        :items="minutes"
                        label="Minute"
                        dense
                        outlined
                        :menu-props="{ value: true }"
                        class="dropdown-field"
                    />
                </div>
                <div class="current-time-link" @click="setCurrentTime">
                    Current time
                </div>
            </v-card-text>
            <v-card-actions class="centered-actions">
                <v-btn text @click="clearFields">Clear</v-btn>
                <v-btn color="primary" text @click="saveTime">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import { ref, computed, watch } from 'vue'
import {logDOM} from "@storybook/testing-library";

export default {
    props: {
        modelValue: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const menu = ref(false)
        const selectedHour = ref(null)
        const selectedMinute = ref('00') // Default to 00 minutes

        const hours = Array.from({ length: 24 }, (_, i) =>
            String(23 - i).padStart(2, '0')
        )
        const minutes = Array.from({ length: 6 }, (_, i) =>
            String(i * 10).padStart(2, '0')
        )

        const formattedTime = computed(() => {
            if (selectedHour.value === null || selectedMinute.value === null)
                return ''
            return `${selectedHour.value}:${selectedMinute.value}`
        })

        const saveTime = () => {
            menu.value = false
            emit('update:modelValue', formattedTime.value)
            console.log('Selected time:', formattedTime.value)
        }

        const setCurrentTime = () => {
            const now = new Date()
            selectedHour.value = String(now.getHours()).padStart(2, '0')
            const minutes = now.getMinutes()
            selectedMinute.value = String(
                Math.floor(minutes / 10) * 10
            ).padStart(2, '0')
        }

        const clearFields = () => {
            selectedHour.value = null
            selectedMinute.value = null
            emit('update:modelValue', '')
        }

        // Watcher to update selectedHour and selectedMinute from modelValue
        watch(
            () => props.modelValue,
            (newValue) => {
                if (newValue) {
                    console.log('asdasda', newValue);
                    const [hour, minute] = newValue.split(':')
                    if (hours.includes(hour) && minutes.includes(minute)) {
                        selectedHour.value = hour
                        selectedMinute.value = minute
                    } else {
                        selectedHour.value = null
                        selectedMinute.value = null
                    }
                } else {
                    selectedHour.value = null
                    selectedMinute.value = null
                }
            },
            { immediate: true, deep:true }
        )

        return {
            menu,
            selectedHour,
            selectedMinute,
            hours,
            minutes,
            formattedTime,
            saveTime,
            setCurrentTime,
            clearFields,
        }
    },
}
</script>

<style scoped>
.d-flex {
    display: flex;
    gap: 10px;
}

.select-time-field {
    max-width: 200px;
}

.dropdown-field {
    max-width: 100px;
}

.time-selection-card {
    max-width: 230px;
}

.centered-actions {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.current-time-link {
    margin-top: 10px;
    font-size: 14px;
    color: #1976d2;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
}

.current-time-link:hover {
    color: #0d47a1;
}
</style>
