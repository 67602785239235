<template>
    <div>
        <!-- Кнопка открытия диалога -->

        <!-- Диалог -->
        <v-dialog v-model="layoutStore.isReminderDialogOpen" max-width="1500px">
            <v-card>
                <!-- Заголовок -->
                <v-card-title style="background-color: #f6f7f9; border: 1px solid #ddd; height: 70px">
                    <v-row style="align-items: center; padding: 10px; text-align: center; margin: 0px">
                        <v-icon style="margin-right: 10px;">mdi-calendar-clock</v-icon>
                        <span class="text-h5">
                            {{ showReminderList ? 'My Reminders' : isEditMode ? 'Edit Reminder' : 'Reminder Setup' }}
                        </span>

                        <v-spacer></v-spacer>
                        <v-btn size="25" elevation="0" variant="plain" icon @click="closeDialog">
                            <v-icon size="25">mdi-close</v-icon>
                        </v-btn>
                    </v-row>
                </v-card-title>

                <!-- Таблица -->
                <v-card-text v-if="showReminderList" style="margin-bottom: 0;padding-bottom: 0" >
                    <v-container v-if="loading" class="text-center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-container>
                    <v-data-table
                        v-if="!loading"
                        :headers="headers"
                        :items="reminders"
                        density="compact"
                        class="custom-table"
                        item-key="type"
                        items-per-page="100"
                        hide-default-footer
                    >
                        <template v-slot:headers="{ columns }">
                            <tr>
                                <template v-for="column in columns" :key="column.key">
                                    <th v-if="column.key == 'email'" style="text-align: center; color: black">
                                        <v-icon>mdi-email</v-icon>
                                    </th>
                                    <th v-else-if="column.key == 'tg'" style="text-align: center; color: black">
                                        <v-icon>mdi-send</v-icon>
                                    </th>
                                    <th style="text-align: center; color: black" v-else>
                                        {{ column.title }}
                                    </th>
                                </template>
                            </tr>
                        </template>

                        <template v-slot:body="{ items }">
                            <tr
                                v-for="(item, index) in items"
                                :key="item.type"
                                :class="{ hover: hoverRow === index, past: isPast(item.reminderOn) }"
                                @mouseover="hoverRow = index "
                                @mouseleave="hoverRow = null"
                            >
                                <td>{{ item.type }}</td>
                                <td>{{ formatDate(item.addedOn) }}</td>
                                <td>{{ item.addedBy }}</td>
                                <td> {{item.reminderOn}}
                                    <v-badge v-if="item.repeat_every_x_days" color="green"  offset-x="4" offset-y="3" floating :content="item.repeat_every_x_days"><v-icon>mdi-calendar-sync-outline</v-icon> </v-badge>
                                   </td>
                                <td>
                                    <v-icon v-if="item.email">mdi-checkbox-marked</v-icon>
                                    <v-icon v-if="!item.email">mdi-checkbox-blank-outline</v-icon>
                                </td>
                                <td>
                                    <v-icon v-if="item.tg">mdi-checkbox-marked</v-icon>
                                    <v-icon v-if="!item.tg">mdi-checkbox-blank-outline</v-icon>
                                </td>
                                <td>
                                    <v-btn variant="plain" icon @click="deleteReminder(item)">
                                        <v-icon color="black">mdi-delete</v-icon>
                                    </v-btn>
                                    <v-btn variant="plain"  icon @click="editReminder(item)">
                                        <v-icon color="black">mdi-pencil</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>

                </v-card-text>

                <!-- Содержание второго окна -->
                <v-card-text v-else>
                    <v-container fluid>
                        <v-row>
                            <!-- Тип напоминания -->
                            <v-col cols="4">
                                <v-select
                                    variant="outlined"
                                    v-model="reminderType"
                                    :items="reminderTypes"
                                    label="Reminder Type"
                                ></v-select>
                            </v-col>
                            <v-col cols="8" style="display: flex; justify-content: center">
                                <v-checkbox
                                    v-model="notifyEmail"
                                    label="Notify via Email"
                                    style="margin-right: 15px"
                                ></v-checkbox>
                                <v-checkbox
                                    v-model="notifyTelegram"
                                    label="Notify via Telegram"
                                ></v-checkbox>
                            </v-col>
                            <!-- Текст напоминания -->
                            <v-col cols="12">
                                <v-textarea
                                    variant="outlined"
                                    v-model="freeText"
                                    label="Enter free text..."
                                    rows="3"
                                ></v-textarea>
                            </v-col>
                            <!-- Кнопки -->
                            <v-col cols="12" v-if="!isEditMode">
                                <v-btn variant="text" @click="addDateTime">
                                    Add specific date & time
                                </v-btn>
                            </v-col>
                        </v-row>

                        <!-- Даты и время -->
                        <v-row
                            v-for="(item, index) in dateTimeItems"
                            :key="index"
                            class="align-center"
                        >
                            <!-- Календарь -->
                            <v-menu
                                v-model="item.dateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                content-class="menu-in-scroll"
                            >
                                <template #activator="{ props }">
                                    <v-text-field
                                        variant="outlined"
                                        v-model="item.date"
                                        label="Date"
                                        readonly
                                        v-bind="props"
                                        style="max-width: 170px; margin-right: 6px"
                                        @click="item.dateMenu = true"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="item.date"
                                    :first-day-of-week="1"
                                    :min="new Date().toISOString().split('T')[0]"
                                    @click:save="item.dateMenu = false"
                                    @click:cancel="item.dateMenu = false"
                                ></v-date-picker>
                            </v-menu>

                            <!-- Time picker -->
                            <time-picker-v v-model="item.time"></time-picker-v>
                            <!-- Timezone -->
                            <v-select
                                variant="outlined"
                                v-model="item.timezone"
                                :items="timezones"
                                label="Timezone"
                                class="ml-2"
                                style="max-width: 150px; margin-right: 10px"
                            ></v-select>
                            <v-text-field
                                v-model="item.repeat_every_x_days"
                                type="number"
                                variant="outlined"
                                min="1"
                                label="Repeat every (days)"
                                class="mr-2"
                                style="max-width: 170px;"
                            ></v-text-field>
                        <v-btn v-if="!isEditMode" density="compact"  style="align-self: center; margin-bottom: 19px" variant="text" icon @click="removeDateTime(index)">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-card-text>

                <!-- Действия -->
                <v-card-actions style="height: 70px;">
                    <v-btn v-if="!showReminderList" color="blue darken-1" variant="text" @click="goBack">Back</v-btn>
                    <v-row align="center" v-if="showReminderList" align-content="center" justify="center">
                        <v-btn  variant="flat" color="primary" @click="openNewReminderDialog">Add Reminder</v-btn>
                    </v-row>
                    <v-btn v-else variant="flat" color="primary" @click="saveReminder">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Snackbar -->
        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
            timeout="3000"
            bottom
            right
        >
            {{ snackbarMessage }}
        </v-snackbar>
    </div>
</template>

<script setup>
import {ref, watch} from 'vue';
import { VTimePicker } from 'vuetify/labs/VTimePicker';
import axios from 'axios';
import {usePage} from "@inertiajs/vue3";
import {useLayoutStore} from "@store/pages/layout-store";
import TimePickerV from "@ui/TimePickerV.vue";
import {format} from "date-fns";
const layoutStore = useLayoutStore();
// const props = defineProps({
//     modelValue: { type: Boolean, default: false }, // v-model
// });

// События для передачи изменений обратно
const emit = defineEmits(['update:modelValue']);

// Локальная копия состояния диалога
const internalDialog = ref(layoutStore.isReminderDialogOpen);
const   formatDate = (date) => {
    return format(new Date(date), 'ddMMMyy').toUpperCase();
};
// Синхронизация внешнего и внутреннего состояния
watch(
    () => layoutStore.isReminderDialogOpen,
    (newValue) => {
        internalDialog.value = newValue;
    }
);

watch(
    () => internalDialog.value,
    (newValue) => {
        if (newValue) {
            showReminderList.value = true;
            fetchReminders(); // Загружаем данные при открытии
        }
        else {
            resetForm();
        }
    }
);
// Состояния
const showReminderList = ref(true);
const isEditMode = ref(false);
const loading = ref(false); // Лоадер
const hoverRow = ref(null); // Добавляем hoverRow как ref
// Поля формы
const reminderType = ref('Follow-Up Reminder');

// Список типов напоминаний
const reminderTypes = [
    'Follow-Up Reminder',
    'PNR Deletion Reminder',
    'Check-in Reminder',
    'Pre-Trip Follow-Up',
    'Post-Trip Follow-Up',
    "Client's Birthday Reminder"
];

const freeText = ref('');
const notifyEmail = ref(true);
const notifyTelegram = ref(true);
const dateTimeItems = ref([]);
const repeatSetting = ref(null);
const timezones = ['RMO', 'NYC'];
const selIs = ref(null);

// Напоминания
const reminders = ref([]);
const headers = ref([
    { title: 'Type', value: 'type' },
    { title: 'Added On', value: 'addedOn' },
    { title: 'Added By', value: 'addedBy' },
    { title: 'Reminder On', value: 'reminderOn' },
    { title: ' <v-icon>mdi-email</v-icon>', value: 'email' },
    { title: 'tg', value: 'tg' },
    { title: 'Actions', value: 'actions', sortable: false },
]);
// Snackbar
const snackbar = ref(false);
const snackbarMessage = ref('');
const snackbarColor = ref('success');

const page = usePage();
function closeDialog() {
    layoutStore.closeReminderDialog()
    resetForm();
}

function openNewReminderDialog() {
    showReminderList.value = false;
    isEditMode.value = false;
    resetForm();
}

function goBack() {
    showReminderList.value = true;
}

function resetForm() {
    reminderType.value = 'Follow-Up Reminder';
    freeText.value = '';
    notifyEmail.value = true;
    notifyTelegram.value = true;
    dateTimeItems.value = [{
        date: null,
        time: null,
        timezone: 'RMO',
        dateMenu: false,
        timeMenu: false,
        repeat_every_x_days: null
    }];
    repeatSetting.value = null;
    isEditMode.value = false;
}
watch(
    () => repeatSetting,
    (newValue, oldValue) => {
        console.log('Изменение глубокого объекта:', newValue.value);
    },
    { deep: true }
);
async function fetchReminders() {
    loading.value = true;
    try {
        // Извлечение request_id из URL
        const pathParts = window.location.pathname.split('/');
        const requestId = pathParts.includes('edit') && pathParts.includes('requests') ? pathParts[pathParts.indexOf('edit') + 1] : null;
        // Формирование query параметров

        // console.log('page', page.props.auth_user.id);
        const params = {
            user_id: page.props.auth_user.id,
        };
        if (requestId) {
            params.request_id = requestId;
        }

        // Выполнение запроса с query параметрами
        const response = await axios.get('/api/reminders', { params });
        console.log('reminders', response);
        reminders.value = response.data.data.map(reminder => ({
            ...reminder,
            addedOn: new Date(reminder.addedOn).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: '2-digit' }),
            reminderOn: `${reminder.reminderOn} ${reminder.reminderTime || ''}`,
        })).sort((a, b) => new Date(a.addedOn) - new Date(b.addedOn));
    } catch (error) {
        snackbarMessage.value = "Error fetching reminders.";
        snackbarColor.value = "error";
        snackbar.value = true;
        console.error('Error fetching reminders:', error.message);
    } finally {
        loading.value = false;
    }
}

async function saveReminder() {
    const getTimeZone = (timezone) => {
        const timeZoneMap = {
            RMO: 'Europe/Chisinau',
        };
        return timeZoneMap[timezone] || 'America/New_York';
    };
    const pathParts = window.location.pathname.split('/');
    const requestId = pathParts.includes('edit') && pathParts.includes('requests') ? pathParts[pathParts.indexOf('edit') + 1] : null;
    const payload = {
        type: reminderType.value,
        text: freeText.value,
        tg: notifyTelegram.value,
        email: notifyEmail.value,
        request_id: requestId,
        created_by_agent_id: page.props.auth_user.id ?? null,
        created_for_agent_id: page.props.auth_user.id ?? null,
        special_repeats: dateTimeItems.value.map(item => {
            const timezone = getTimeZone(item.timezone);
            const localDate = new Date(item.date);
            const options = { timeZone: timezone, year: 'numeric', month: '2-digit', day: '2-digit' };
            const formattedDate = new Intl.DateTimeFormat('en-CA', options).format(localDate);
            return {
                date: formattedDate,
                time: item.time,
                timezone: item.timezone,
                repeat_every_x_days: item.repeat_every_x_days
            };
        }),
    };

    if (!freeText.value) {
        snackbarMessage.value = "You must fill in the reminder text.";
        snackbarColor.value = "error";
        snackbar.value = true;
        return;
    }

    if (!payload.repeat_every_x_days && payload.special_repeats.length === 0) {
        snackbarMessage.value = "Specify a repeat schedule or special dates.";
        snackbarColor.value = "error";
        snackbar.value = true;
        return;
    }

    try {
        if (isEditMode.value) {
            await axios.put(`/api/reminders/${selIs.value}`, payload);
        }
        else {
            await axios.post('/api/reminders', payload);
        }
        snackbarMessage.value = "Reminder saved successfully!";
        snackbarColor.value = "success";
        snackbar.value = true;
        fetchReminders(); // Обновляем список
        resetForm();
        showReminderList.value = true;
    } catch (error) {
        snackbarMessage.value = "Error saving reminder.";
        snackbarColor.value = "error";
        snackbar.value = true;
        console.error('Error saving reminder:', error.message);
    }
}

function addDateTime() {
    dateTimeItems.value.push({
        date: null,
        time: null,
        timezone: 'RMO',
        dateMenu: false,
        timeMenu: false,
        repeat_every_x_days: null
    });
}

function removeDateTime(index) {
    dateTimeItems.value.splice(index, 1);
}


async function deleteReminder(item) {
    const confirmDeletion = confirm(`Are you sure you want to delete the reminder "${item.type}"?`);
    if (!confirmDeletion) return;

    try {
        await axios.delete(`/api/reminders/${item.id}`); // Запрос на удаление
        const index = reminders.value.indexOf(item);
        if (index > -1) {
            reminders.value.splice(index, 1); // Удаляем из локального списка
        }

        snackbarMessage.value = "Reminder deleted successfully.";
        snackbarColor.value = "success";
        snackbar.value = true;
    } catch (error) {
        console.error('Error deleting reminder:', error.message);
        snackbarMessage.value = "Failed to delete reminder.";
        snackbarColor.value = "error";
        snackbar.value = true;
    }
}
function isPast(date) {
    return new Date(date) < new Date();
}

async function editReminder(item) {
    console.log('Editing reminder:', item.id);
    loading.value = true; // Устанавливаем состояние загрузки
    selIs.value = item.id
    try {
        const response = await axios.get(`/api/reminders/${item.id}`); // Загружаем данные напоминания
        const reminder = response.data.data;
        console.log('reminder', reminder)

        // Распределяем данные из API в форму
        reminderType.value = reminder.type || 'general';
        freeText.value = reminder.text || '';
        notifyEmail.value = reminder.email || false;
        notifyTelegram.value = reminder.tg || false;

        // Обработка специальных повторов (specific date & time)
        dateTimeItems.value = [{
            date: new Date(reminder.date),
            time: reminder.time,
            timezone: reminder.timezone,
            dateMenu: false,
            timeMenu: false,
            repeat_every_x_days: reminder.repeat_every_x_days
        }];
        // Переключаемся в режим редактирования
        isEditMode.value = true;
        showReminderList.value = false;
    } catch (error) {
        console.error('Error fetching reminder for edit:', error.message);
        snackbarMessage.value = 'Failed to load reminder for editing.';
        snackbarColor.value = 'error';
        snackbar.value = true;
    } finally {
        loading.value = false;
    }
}

</script>
<style>
.custom-table {
    border: 1px solid #ddd;
    border-collapse: collapse;
}

.custom-table thead {
    background-color: #f6f7f9;
    color: #495057;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
}

.custom-table tbody tr {
    transition: background-color 0.2s;
}

.custom-table tbody tr.hover {
    background-color: #f1f3f5;
}

.custom-table tbody tr.past {
    background-color: #f8d7da;
}

.custom-table th,
.custom-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
}
</style>
