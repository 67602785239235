<template>
    <!-- Условие: рендерить диалог только если selectedPqs не пуст и dialog открыт -->
    <v-dialog v-model="dialogModel" width="800px" height="454px">
        <!-- Содержимое диалoга: либо контент, либо индикатор загрузки -->
        <v-card v-if="dialogModel">
            <v-card-title style="background-color: #f8f9fa; height: 60px; align-content: center" class="d-flex justify-space-between align-center">
                <div>
                    Send SMS Template
                </div>
                <v-btn
                    icon="mdi-close"
                    variant="text"
                    @click="closeDialog"
                ></v-btn>
            </v-card-title>

            <!-- Проверяем, идет ли отправка (isLoading). Если да, показываем индикатор загрузки -->
            <v-card-text v-if="!isLoading">
                <!-- Поле "From" -->
                <v-select
                    variant="outlined"
                    density="compact"
                    label="From"
                    v-model="from"
                    title="title"
                    return-object
                    :items="fromOptions"
                    dense
                />

                <!-- Поле "To" (Phone) -->
                <v-select
                    variant="outlined"
                    density="compact"
                    label="Client phone"
                    v-model="to"
                    :items="phones"
                    item-title="phone"
                    item-value="id"
                >
                    <template #item="{ item, props: { onClick } }">
                        <v-list-item :title="item.title" @click="onClick">
                            <template v-slot:append>
                                {{item.type === 1 ? 'Personal' : 'Shared'}} {{item.raw.main ? " Main" : ''}}
                            </template>
                        </v-list-item>
                    </template>
                </v-select>

                <!-- Сообщение -->
                <v-textarea
                    v-model="message"
                    variant="outlined"
                    rows="5"
                    auto-grow
                    :maxlength="1000"
                ></v-textarea>

            </v-card-text>

            <!-- Индикатор загрузки -->
            <v-card-text v-else>
                <v-container class="d-flex justify-center align-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-container>
            </v-card-text>

            <!-- Карточка с кнопками действий (скрыта при isLoading) -->
            <v-card-actions v-if="!isLoading" style="background-color: #f8f9fa; height: 70px; padding: 0 20px 0 20px">
                <div class="text-right grey--text">{{ message.length }} symbols</div>
                <v-spacer></v-spacer>

                <!-- Кнопка "Copy" -->
                <v-btn variant="flat" density="default" color="primary" @click="copyMessage" append-icon="mdi-content-copy">Copy</v-btn>

                <!-- Кнопка "Send" -->
                <v-btn variant="flat"  :disabled="!from || !to" density="default" color="primary" @click="sendSms" append-icon="mdi-send">Send</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import {computed, onMounted, ref, watch} from 'vue';
import {usePage} from "@inertiajs/vue3";
import axios from "axios";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {usePageRequestShowStore} from "@store/pages/page-request-edit";

const props = defineProps({
    selectedPqs: Object, // Объект с ключами PQ ID и значениями позиций
    modelValue: Boolean,
});
const count = ref(null);
const text = ref ('Send SMS');
const updateCount = () => {
    count.value = Object.keys(props.selectedPqs || {}).length;
    if (count.value > 0 ) {
        text.value = 'Send SMS (' + count.value + ')'
    }
    else
    {
        text.value = 'Send SMS';
    }
};
watch(
    () => props.selectedPqs, // Следим за пропсом
    (newValue, oldValue) => {
        updateCount();
    },
    { deep: true } // Указываем deep: true для слежения за вложенными изменениями
);
const selectedPqsExist = computed(() => {
    return props.selectedPqs && Object.keys(props.selectedPqs).length > 0;
});
const emit = defineEmits(['update:modelValue']);

const dialogModel = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
});
const AlertsManager = useAlertsManagerStore();
// const dialog = ref(false);
const from = ref('');
const to = ref('');
const isLoading = ref(false); // состояние загрузки
const message = ref('');
const page = usePage();
const pageProps = page.props;
const agents = pageProps.agent;
const client = pageProps.client.data;
const curReqId = pageProps.currentRequest.data.id;
const pqs = pageProps.pqs[curReqId];

function calculateMainAirline(flightLegs) {
    const airlineTimes = {};
    // Пройтись по каждому элементу flight_details
    flightLegs.forEach(leg => {
        const airline = leg.airline;
        const timeTravel = leg.time_travel; // Время полета в формате "HH:MM"

        // Разделим время полета на часы и минуты
        const [hours, minutes] = timeTravel.split(":").map(Number);
        const totalMinutes = hours * 60 + minutes; // Конвертируем в минуты

        // Если такая авиакомпания уже есть в объекте, суммируем время
        if (airlineTimes[airline]) {
            airlineTimes[airline] += totalMinutes;
        } else {
            airlineTimes[airline] = totalMinutes; // Инициализируем
        }
    });

    // Найдем авиакомпанию с максимальным временем полета
    let mainAirline = null;
    let maxTime = 0;

    for (const airline in airlineTimes) {
        if (airlineTimes[airline] > maxTime) {
            maxTime = airlineTimes[airline];
            mainAirline = airline;
        }
    }

    return mainAirline;
}
const recalculateMessage = () => {
    const pqMessages = Object.entries(props.selectedPqs)
        .map(([pqId, position]) => {
            const selectedPq = pqs.find(i => i.data.id === Number(pqId));  // Находим PQ по его ID

            // Получаем основной airline для каждого selectedPq
            const mainAirline = calculateMainAirline(selectedPq.data.pq_flight_legs);

            // Извлекаем цену и конвертируем её в число
            const price = parseFloat(selectedPq.data.pq_passengers.sell.adults) ?? 0;

            const hashId = selectedPq?.data.hash_id; // Извлекаем hash_id для ссылки

            return {
                position,
                mainAirline,
                price,
                hashId
            };
        })
        .sort((a, b) => a.price - b.price) // Сортировка по возрастанию цены
        .map((item, index) => {
            // Форматирование цены с разделением на тысячи и двумя знаками после запятой
            const formattedPrice = item.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

            // Пересчет позиции с учетом нового порядка
            return `#${index + 1}, ${item.mainAirline}: $${formattedPrice}: https://offer.vipfares.com/${item.hashId}`;
        })




    // Используем выбранного агента из поля "From"
    const selectedAgentName = from.value ? from.value.agent_name : (agents[0]?.first_name ?? 'NOT SELECTED');
    // Собираем итоговое сообщение с динамически выбранным агентом
    if (count.value > 0) {
        message.value = `Hi ${client.first_name}, ${selectedAgentName} from VIPFARES here. Please find below our best offers for your trip:\n` + pqMessages.join('\n');

    }
    else
    {
        message.value = '';
    }
};

watch(() => props.selectedPqs, (newPqs) => {
    if (selectedPqsExist) {
        // Обновляем сообщение при изменении selectedPqs
        recalculateMessage();
    }
}, { deep: true, immediate: true });

watch(from, (newFrom) => {
    if (selectedPqsExist && newFrom) {
        // Обновляем сообщение при изменении агента в поле "From"
        recalculateMessage();
    }
});

// Функция для пересчёта сообщения
const dialCodes = ref({});
const loadDialCode = async (dial_code_id) => {
    try {
        const response = await axios.post('https://infohub.vipfares.com/api/get/dial-code', {
            dial_code_id
        });
        return response.data.data.code;
    } catch (error) {
        console.error('Error fetching dial code:', error);
        return '';
    }
};

const loadDialCodes = async () => {
    const requests = agents.map(async agent => {
        dialCodes.value[agent.phone.dial_code_id] = await loadDialCode(agent.phone.dial_code_id);
    });

    await Promise.all(requests);
};

onMounted(() => {
    loadDialCodes();
});

const fromOptions = computed(() => {
    return agents.map(agent => {
        const dialCode = dialCodes.value[agent.phone.dial_code_id] || 'x';
        const phone = `${dialCode} ${agent.phone.phone}`;
        return {
            title: `${agent.first_name} (${phone})`,
            agent_name: agent.first_name,
            value: phone
        };
    });
});

const phones = ref([]);
if (pageProps.client && pageProps.client.data && pageProps.client.data.phone) {
    phones.value = pageProps.client.data.phone.map(p => ({
        id: p.id,
        phone: `${p.dial_code} ${p.phone}`,
        type: p.pivot?.type_id ?? 1,
        main: p.pivot?.main ?? false
    }));
}

const copyMessage = () => {
    navigator.clipboard.writeText(message.value)
    AlertsManager.add('Message copied to clipboard!')
}
const pageRequestShowStore = usePageRequestShowStore();
const sendSms = async () => {
    isLoading.value = true; // Включаем индикатор загрузки

    const payload = {
        from: from.value.value,
        to: to.value,
        message: message.value,
        id: pageProps.currentRequest.data.id
    };

    try {
        const response = await axios.post('/api/pq/sms', payload);
        console.log('SMS sent successfully:', response.data);
        AlertsManager.add('SMS sent successfully')
        pageRequestShowStore.incrementSMSCount();
    } catch (error) {
        const err = error.response?.data?.error
            ?? '';
        AlertsManager.add(`Failed to send SMS: ${err}`, 'danger');
    } finally {
        isLoading.value = false; // Отключаем индикатор загрузки после завершения
    }
};
const closeDialog = () => {
    dialogModel.value = false;
};

</script>

<style scoped>
.text-right {
    font-size: 12px;
}
</style>
